import React, { useEffect, useState } from 'react'
import BarChart from '../components/Barchart'
import { Box, Button, Grid, Switch } from '@mui/material'
import CustomTable from '../components/Table';
import { tableData } from '../utils/tableData';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../utils/Context';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PieChart from '../components/Piechart';


const Dashboard4 = () => {
  const [activeChart, setActiveChart] = useState(null);
  const [activeCate, setActiveCate] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isToolBarOpen, setIsToolBarOpen] = useState(false);
  const { barChartData, isEditModeOn, setIsEditModeOn, customChartsData, setCustomChartsData } = useGlobalContext();
  let pieData = {};
  let isPieChart = customChartsData?.[activeChart]?.type === 'pie';
  if(isPieChart){
    pieData = customChartsData?.[activeChart]?.series?.[0]?.data?.[activeCate];
  } else {
    pieData = customChartsData?.[activeChart]?.rows?.[activeCate];
  }
  const navigate = useNavigate();
  const handleClick = (activeNo) => {
    setActiveChart(activeNo);
  }
  const handleClose = () => {
    setActiveChart(null);
  }

  const toggleToolbar = () => {
    setIsToolBarOpen(!isToolBarOpen);
  }
  const inputType = tableData[activeChart]?.inputType;

  const user = localStorage.getItem('user');

  function onDocumentLoadSuccess({ numPages }) {
    console.log('load success');
    setNumPages(numPages);
  }

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user])
  return (
    <div className='pie-parent'>
    <div className='timewise-charts'>
        <div
        className='right-font'
      >
            Weekly
        </div>
      {/* <Grid container spacing={3} padding={3} paddingRight={0} paddingLeft={0}> */}
      <Grid container spacing={3} paddingBottom={3} paddingTop={3}>
        {
          customChartsData?.map((data, ind) => {
            const chartType = data?.type === 'pie' ? 'pie' : 'bar';
              return (
                <React.Fragment key={`Weekly-${ind}`}>
               {ind <= 3 ? <>
                {/* <Grid item md={3} key={ind}> */}
                <Grid item md={3} key={ind}>
                  <Box height={'100%'}>
                    {ind === activeChart ?
                      pieData?.inputType === 'pdf' ?
                        <Box style={{ position: 'relative', height: '100%' }}>
                          <Box onClick={handleClose} className='close-icon-pdf'>
                            <AiOutlineCloseCircle color='red' fontSize={'18'} />
                          </Box>
                          <Box onClick={toggleToolbar} className={`burger-icon ${isToolBarOpen ? 'bg-dark' : ''}`}>
                            <GiHamburgerMenu color='blue' fontSize={'30'} />
                          </Box>

                          {isToolBarOpen ?
                            <object width="100%" height="100%" data={`${pieData?.url}`}>
                            </object> :
                            <>
                              <object width="100%" height="100%" data={`${pieData?.url}#toolbar=0`}>
                              </object>
                            </>}
                        </Box>
                        :
                        pieData?.inputType === 'ppt' ?
                          <Box style={{ position: 'relative', height: '100%' }}>
                            <Box onClick={handleClose} className='close-icon-pdf'>
                              <AiOutlineCloseCircle color='red' fontSize={'18'} />
                            </Box>
                            <DocViewer
                              documents={[{ uri: pieData?.url }]}
                              pluginRenderers={DocViewerRenderers}
                              config={{ header: { disableHeader: true } }} />
                          </Box>
                          : chartType === 'pie' ?  <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />
                      : chartType === 'pie' ?  <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />}

                  </Box>
                </Grid>
               
               </> : <></>}
                </React.Fragment>
              )
          })
        }
      </Grid>
    </div>
    <div className='timewise-charts'>
        <div
        className='right-font'>
            Monthly
        </div>
      {/* <Grid container spacing={3} padding={3} paddingRight={0} paddingLeft={0}> */}
      <Grid container spacing={3} paddingBottom={3}>
        {
          customChartsData?.map((data, ind) => {
            const chartType = data?.type === 'pie' ? 'pie' : 'bar';
              return (
                <React.Fragment key={`Montly-${ind}`}>
               {/* {ind > 3 && ind< 8 ? <> */}
               {ind > 3 && ind< 8 ? <>
                {/* <Grid item md={3} key={ind}> */}
                <Grid item md={3} key={ind}>
                  <Box height={'100%'}>
                    {ind === activeChart ?
                      pieData?.inputType === 'pdf' ?
                        <Box style={{ position: 'relative', height: '100%' }}>
                          <Box onClick={handleClose} className='close-icon-pdf'>
                            <AiOutlineCloseCircle color='red' fontSize={'18'} />
                          </Box>
                          <Box onClick={toggleToolbar} className={`burger-icon ${isToolBarOpen ? 'bg-dark' : ''}`}>
                            <GiHamburgerMenu color='blue' fontSize={'30'} />
                          </Box>

                          {isToolBarOpen ?
                            <object width="100%" height="100%" data={`${pieData?.url}`}>
                            </object> :
                            <>
                              <object width="100%" height="100%" data={`${pieData?.url}#toolbar=0`}>
                              </object>
                            </>}
                        </Box>
                        :
                        pieData?.inputType === 'ppt' ?
                          <Box style={{ position: 'relative', height: '100%' }}>
                            <Box onClick={handleClose} className='close-icon-pdf'>
                              <AiOutlineCloseCircle color='red' fontSize={'18'} />
                            </Box>
                            <DocViewer
                              documents={[{ uri: pieData?.url }]}
                              pluginRenderers={DocViewerRenderers}
                              config={{ header: { disableHeader: true } }} />
                          </Box>
                          :  chartType === 'pie' ?  <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />
                          : chartType === 'pie' ?  <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />}

                  </Box>
                </Grid>
               
               </> : <></>}
                </React.Fragment>
              )
          })
        }
      </Grid>
    </div>
    <div className='timewise-charts'>
        <div
        className='right-font'
>
            Yearly
        </div>
      {/* <Grid container spacing={3} padding={3} paddingRight={0} paddingLeft={0}> */}
      <Grid container spacing={3} paddingBottom={3}>
        {
          customChartsData?.map((data, ind) => {
            const chartType = data?.type === 'pie' ? 'pie' : 'bar';
              return (
                <React.Fragment key={`Yearly-${ind}`}>
               {/* {ind >= 8 ? <> */}
               {ind > 7 && ind < 12   ? <>
                {/* <Grid item md={3} key={ind}> */}
                <Grid item md={3} key={ind}>
                  <Box height={'100%'}>
                    {ind === activeChart ?
                      pieData?.inputType === 'pdf' ?
                        <Box style={{ position: 'relative', height: '100%' }}>
                          <Box onClick={handleClose} className='close-icon-pdf'>
                            <AiOutlineCloseCircle color='red' fontSize={'18'} />
                          </Box>
                          <Box onClick={toggleToolbar} className={`burger-icon ${isToolBarOpen ? 'bg-dark' : ''}`}>
                            <GiHamburgerMenu color='blue' fontSize={'30'} />
                          </Box>

                          {isToolBarOpen ?
                            <object width="100%" height="100%" data={`${pieData?.url}`}>
                            </object> :
                            <>
                              <object width="100%" height="100%" data={`${pieData?.url}#toolbar=0`}>
                              </object>
                            </>}
                        </Box>
                        :
                        pieData?.inputType === 'ppt' ?
                          <Box style={{ position: 'relative', height: '100%' }}>
                            <Box onClick={handleClose} className='close-icon-pdf'>
                              <AiOutlineCloseCircle color='red' fontSize={'18'} />
                            </Box>
                            <DocViewer
                              documents={[{ uri: pieData?.url }]}
                              pluginRenderers={DocViewerRenderers}
                              config={{ header: { disableHeader: true } }} />
                          </Box>
                          :  chartType === 'pie' ?  <PieChart url={`/chart/pie/${ind}?type=custom`} isCustom={true} data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart isCustom={true} data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />
                          : chartType === 'pie' ?  <PieChart url={`/chart/pie/${ind}?type=custom`} isCustom={true} data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/> : <BarChart isCustom={true} data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />}

                  </Box>
                </Grid>
               
               </> : <></>}
                </React.Fragment>
              )
          })
        }
      </Grid>
    </div>
      <Box className='footer-wrapper' style={{paddingLeft: '5%'}}>
        <Box>
          <Button variant='outlined' onClick={() => {
            localStorage.removeItem('user');
            navigate('/login');
          }}>
            Logout
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Dashboard4