import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Dashboard1';
import AddChart from './pages/AddBarChart';
import Login from './pages/Login';
import AddPieChart from './pages/AddPieChart';
import SelectDashboard from './pages/SelectDashboard';
import Dashboard2 from './pages/Dashboard2';
import Dashboard4 from './pages/Dashboard4';
import Dashboard3 from './pages/Dashboard3';

function App() {
  return (
    <Router>
      <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/select-dashboard' element={<SelectDashboard />} />
      <Route path='/chart/bar' element={<Home />} />
      <Route path='/chart/pie4-3' element={<Dashboard2 />} />
      <Route path='/chart/pie3-3' element={<Dashboard3 />} />
      <Route path='/chart/custom' element={<Dashboard4 />} />
      <Route path='/login' element={<Login />} />
      <Route path='/chart' element={<AddChart />} />
      <Route path='/chart/bar/:id' element={<AddChart />} />
      <Route path='/chart/pie/:id' element={<AddPieChart />} />
      </Routes>
      </Router>
  );
}

export default App;
