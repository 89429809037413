import React, { useEffect, useState } from 'react'
import BarChart from '../components/Barchart'
import { Box, Button, Grid, Switch } from '@mui/material'
import CustomTable from '../components/Table';
import { tableData } from '../utils/tableData';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../utils/Context';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PieChart from '../components/Piechart';


const Home = () => {
  const [activeChart, setActiveChart] = useState(null);
  const [activeCate, setActiveCate] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isToolBarOpen, setIsToolBarOpen] = useState(false);
  const { barChartData, isEditModeOn, setIsEditModeOn } = useGlobalContext();
  // Main current Row Data
  const location = useLocation();
  const currentData = barChartData?.[activeChart]?.rows?.[activeCate];
  const pieData = barChartData?.[activeChart]?.series?.[0]?.data?.[activeCate];
  const navigate = useNavigate();
  const handleClick = (activeNo) => {
    setActiveChart(activeNo);
  }
  const handleClose = () => {
    setActiveChart(null);
  }

  const toggleToolbar = () => {
    setIsToolBarOpen(!isToolBarOpen);
  }
  const inputType = tableData[activeChart]?.inputType;

  const user = localStorage.getItem('user');

  function onDocumentLoadSuccess({ numPages }) {
    console.log('load success');
    setNumPages(numPages);
  }

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if(location?.pathname === '/'){
      navigate('/select-dashboard');
    }
  }, [user])
  return (
    <div className='home-wrapper'>
      <Grid container spacing={1} padding={'2vh 2vw'}>
        {
          barChartData?.map((data, ind) => {
            const type = data?.type || "bar";
            if (type === "bar") {
              return (
                <><Grid item md={4} key={ind}>
                  <Box height={'100%'}>
                    {ind === activeChart ?
                      currentData?.inputType === 'pdf' ?
                        <Box style={{ position: 'relative', height: '100%' }}>
                          <Box onClick={handleClose} className='close-icon-pdf'>
                            <AiOutlineCloseCircle color='red' fontSize={'18'} />
                          </Box>
                          <Box onClick={toggleToolbar} className={`burger-icon ${isToolBarOpen ? 'bg-dark' : ''}`}>
                            <GiHamburgerMenu color='blue' fontSize={'30'} />
                          </Box>

                          {isToolBarOpen ?
                            <object width="100%" height="100%" data={`${currentData?.url}`}>
                            </object> :
                            <>
                              <object width="100%" height="100%" data={`${currentData?.url}#toolbar=0`}>
                              </object>
                            </>}
                        </Box>
                        :
                        currentData?.inputType === 'ppt' ?
                          <Box style={{ position: 'relative', height: '100%' }}>
                            <Box onClick={handleClose} className='close-icon-pdf'>
                              <AiOutlineCloseCircle color='red' fontSize={'18'} />
                            </Box>
                            <DocViewer
                              documents={[{ uri: currentData?.url }]}
                              pluginRenderers={DocViewerRenderers}
                              config={{ header: { disableHeader: true } }} />
                          </Box>
                          : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />
                      : <BarChart data={data} handleClick={handleClick} isEdit={isEditModeOn} setActiveCate={setActiveCate} currentItem={ind} />}

                  </Box>
                </Grid>
                </>
              )
            }
            //  else if (type === 'pie') {
            //   console.log('currentData', pieData)
            //   return (
            //     <><Grid item md={4} key={ind}>
            //       {/* <Box height={'100%'}> */}
            //       <Box height={'20rem'}>
            //         {ind === activeChart ?
            //           pieData?.inputType === 'pdf' ?
            //             <Box style={{ position: 'relative', height: '100%' }}>
            //               <Box onClick={handleClose} className='close-icon-pdf'>
            //                 <AiOutlineCloseCircle color='red' fontSize={'18'} />
            //               </Box>
            //               <Box onClick={toggleToolbar} className={`burger-icon ${isToolBarOpen ? 'bg-dark' : ''}`}>
            //                 <GiHamburgerMenu color='blue' fontSize={'30'} />
            //               </Box>

            //               {isToolBarOpen ?
            //                 <object width="100%" height="100%" data={`${pieData?.url}`}>
            //                 </object> :
            //                 <>
            //                   <object width="100%" height="100%" data={`${pieData?.url}#toolbar=0`}>
            //                   </object>
            //                 </>}
            //             </Box>
            //             :
            //             pieData?.inputType === 'ppt' ?
            //               <Box style={{ position: 'relative', height: '100%' }}>
            //                 <Box onClick={handleClose} className='close-icon-pdf'>
            //                   <AiOutlineCloseCircle color='red' fontSize={'18'} />
            //                 </Box>
            //                 <DocViewer
            //                   documents={[{ uri: pieData?.url }]}
            //                   pluginRenderers={DocViewerRenderers}
            //                   config={{ header: { disableHeader: true } }} />
            //               </Box>
            //               : <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/>
            //           : <PieChart data={data} handleClick={handleClick} setActiveCate={setActiveCate} currentItem={ind}/>}
            //       </Box>
            //     </Grid>
            //     </>
              // )
            // }
          })
        }
      </Grid>
      <Box className='footer-wrapper'>
        {/* <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
          Read Mode<Switch checked={isEditModeOn} onChange={(e) => setIsEditModeOn(!isEditModeOn)} />Edit Mode
        </Box> */}
        <Box>
          <Button variant='outlined' onClick={() => {
            localStorage.removeItem('user');
            navigate('/login');
          }}>
            Logout
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Home