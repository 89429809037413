import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Card } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../utils/Context';
import { useTheme } from '@mui/material/styles';
import {  useMediaQuery } from '@mui/material';


const PieChart = ({handleClick, currentItem, setActiveCate, isEdit, data, isCustom, url}) => {
  const { isEditModeOn } = useGlobalContext();

    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
    const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
    const isxlScreen = useMediaQuery(theme.breakpoints.only('xl'));
    const isCustomPath = location.pathname.includes('custom');
    const is4By3 = location.pathname.includes('pie4-3');
    const is3By3 = location.pathname.includes('pie3-3');
    let legend = {enabled: true, itemStyle: {fontSize: data?.legendFontSize ? `${data?.legendFontSize}px` : '6px', fontWeight: '400'}};
    if(data?.legendPosition === 'left'){
        legend = {
            ...legend,
            align: 'left',
            layout: "vertical",
            verticalAlign: 'top',
            x: 0,
            y: 100
        }
    } else if(data?.legendPosition === 'right'){
        legend = {
            ...legend,
            align: 'right',
            layout: "vertical",
            x: 0,
            y: 100,
            verticalAlign: 'top',
        }
    } else if(data?.legendPosition === 'bottom'){
        legend = {
            ...legend,
            align: 'center'
        }
    } else {
        legend = {
            ...legend,
            align: 'right',
            layout: "vertical",
            x: 0,
            y: 100,
            verticalAlign: 'top',
        }
    }
    const options = {
        credits: {
            enabled: false
        },
        chart: {
            type: 'pie',
            height: isCustomPath ? '70%' : (is4By3 ? '70%' : '50%'),
            // height: '100%',
            backgroundColor: '#F8F8F8',
            className: 'chart-wrapper',
            events: {
                load: function() {
                    var series = this.series[0];
                  setTimeout(function(){
                    const sum = series?.yData?.reduce((a,b)=> a + b);
                    (series?.yData)?.forEach(function(point, i){
                        const percentage = ((point*100)/sum);
                        if (percentage > 10) {
                          series?.points?.[i]?.update({dataLabels:{distance: -30, color: 'white'}});
                        }
                      });
                  }, 200);
                }
              }
        },
        colors: [
            '#526BE8', 
            '#A05ACE', 
            '#FFA93A', 
            '#FF2C38', 
            '#64E572', 
            '#FF9655', 
            '#FFF263', 
            '#6AF9C4'
          ],
        title: {
            // text: data?.name || 'Test',
            style: {
                fontSize: isxlScreen ? '1.2rem' : isLgScreen ? '0.8rem' : isMdScreen ? '0.7rem' : '0.6rem'
             },
            text: (data?.showTitle || data?.showTitle === undefined) ? data?.name || 'Test' : null,
            // text: data?.name,
        },
        tooltip: {
            pointFormat: '{series.name}: <br>{point.percentage:.1f} %<br>value: {point.y}'
          },
        // xAxis: {
        //     labels:{
        //         style: {
        //             fontSize:'9px'
        //         }
        //     },
        //     crosshair: true,
        //     ...(data?.showXAxis && {title: {
        //         text: data?.xAxisTitle
        //     }})
        // },
        // yAxis: {
        //     min: 0,
        //     title: {
        //         text: data?.yAxisTitle
        //     },
        //     allowDecimals: true
        // },
        // legend,
        legend: {
            ...legend,
            enabled: data?.showLegends ? true : ((currentItem === 0 && data?.showLegends !== false) ? true : false),
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                allowPointSelect: true,
                borderColor:'red',
                cursor: 'pointer',
                borderWidth: 0,
                slicedOffset: 0,
                dataLabels: {
                    // enabled: false,
                    distance: 1,
                    enabled: true,
                    shadow : false,
                    color: 'black',
                    style: {
                        fontSize: data?.dataLabelFontSize ?`${data?.dataLabelFontSize}px` : '10px'
                    },
                    format: '{point.percentage:.1f} %'
                    // format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    // format: '<b>{point.name}</b>'
                },
                showInLegend: true,
                    events: {
                        click: function (event) {
                            if(isEditModeOn){
                                let navUrl = `/chart/pie/${currentItem}`;
                                if(is4By3 || is3By3){
                                    navUrl = `${navUrl}?is4By3=${is4By3 ? true : false}`
                                }
                                if(data?.isCustom){
                                    navUrl = `/chart/pie/${currentItem}?type=custom`
                                }
                                navigate(navUrl);
                            } else {
                                handleClick(currentItem);
                                setActiveCate(event.point.index);
                            }
                        }
                    }
            },
        },
        series: data?.series || []
    };
    return (
        <div>
            <Card className='card-wrapper'>
                <HighchartsReact highcharts={Highcharts} options={options}/>
            </Card>
        </div>
    )
}
export default PieChart;