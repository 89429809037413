import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SelectDashboard = () => {
    const [dashboard, setDashboard] = useState('');
    const navigate = useNavigate();

    const user = localStorage.getItem('user');

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user]);
    return (
        <div className='select-dash-wrapper'>
            <div className='select-header'>
                <div className='select-title'>Select Dashboard</div>
            </div>
            <div className='select-section'>
                <div className='dash-box' onClick={() => setDashboard('dash-1')}>
                    <div className='text-center pb-2 font-20'>Dashboard 1 - bar charts</div>
                    <input
                        type='radio'
                        name='select'
                        id={'dash-1'}
                        value={'dash-1'}
                        checked={dashboard === 'dash-1'}
                    />
                </div>
                <div className='dash-box' onClick={() => setDashboard('dash-2')}>
                    <div className='text-center pb-2 font-20'>Dashboard 2 - pie charts</div>
                    <div className='text-center pb-2 font-20'>(4 X 3)</div>
                    <input
                        type='radio'
                        name='select'
                        id={'dash-1'}
                        value={'dash-2'}
                        checked={dashboard === 'dash-2'}
                    />
                </div>
            </div>
            <div className='select-section'>
                <div className='dash-box' onClick={() => setDashboard('dash-3')}>
                    <div className='text-center pb-2 font-20'>Dashboard 3 - pie charts</div>
                    <div className='text-center pb-2 font-20'>(3 X 3)</div>
                    <input
                        type='radio'
                        name='select'
                        id={'dash-1'}
                        value={'dash-3'}
                        checked={dashboard === 'dash-3'}
                    />
                </div>
                <div className='dash-box' onClick={() => setDashboard('dash-4')}>
                    <div className='text-center pb-2 font-20'>Dashboard 4 - Custom</div>
                    <input
                        type='radio'
                        name='select'
                        id={'dash-1'}
                        value={'dash-4'}
                        checked={dashboard === 'dash-4'}
                    />
                </div>
            </div>
            {/* <div className='select-section'>
                <div className='dash-box' onClick={() => setDashboard('dash-3')}>
                    <div className='text-center pb-2 font-20'>Dashboard 3 - Custom</div>
                    <input
                        type='radio'
                        name='select'
                        id={'dash-3'}
                        value={'dash-3'}
                        checked={dashboard === 'dash-3'}
                    />
                </div>
            </div> */}
            <div className='bottom-sec'>
                <Button
                    variant='contained'
                    type='submit'
                    className='select-btn'
                    onClick={() => {
                      const linkMapping = {
                        'dash-1': '/chart/bar',
                        'dash-2': '/chart/pie4-3',
                        'dash-3': '/chart/pie3-3',
                        'dash-4': '/chart/custom'
                      }
                        if (dashboard) {

                            // navigate(
                            //     dashboard === 'dash-1'
                            //         ? '/chart/bar'
                            //         : dashboard === 'dash-2'
                            //         ? '/chart/pie'
                            //         : '/chart/custom'
                            // );
                            navigate(linkMapping[dashboard]);
                        }
                    }}
                >
                    Proceed
                </Button>
            </div>
        </div>
    );
};

export default SelectDashboard;
