// import link from '../pdfs/certificate(1).pdf';
export const tableData = [
    {
        name: "January",
        y: 20000,
        inputType: "row-data",
        data: [
            {
                date: "2023-01-01",
                reason: "Fuel",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-01-05",
                reason: "Grocery",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-01-10",
                reason: "Rent",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-01-13",
                reason: "Savings",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-01-20",
                reason: "Electricity Bill",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-01-25",
                reason: "Others",
                amount: 2000,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "February",
        y: 15000,
        inputType: "row-data",
        data: [
            {
                date: "2023-02-03",
                reason: "Food",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-02-05",
                reason: "Vehicle Insurance",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-02-10",
                reason: "Rent",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-02-13",
                reason: "Fashion Expense",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-02-15",
                reason: "Donate",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-02-20",
                reason: "Others",
                amount: 1000,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "March",
        y: 17000,
        inputType: "row-data",
        data: [
            {
                date: "2023-03-03",
                reason: "Recharge",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-03-05",
                reason: "Tax",
                amount: 3000,
                id: new Date().getTime()
            },
            {
                date: "2023-03-10",
                reason: "Travelling",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-03-13",
                reason: "Debt payments",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-03-15",
                reason: "Child care",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-03-20",
                reason: "Others",
                amount: 2000,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "April",
        y: 18000,
        inputType: "row-data",
        data: [
            {
                date: "2023-04-03",
                reason: "Groceries",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-04-05",
                reason: "Entertainment",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-04-10",
                reason: "Childcare and School Costs",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-04-13",
                reason: "Health Insurance",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-04-15",
                reason: "Internet bill",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-04-20",
                reason: "Gym membership",
                amount: 3000,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "May",
        y: 19500,
        inputType: "pdf",
        data: [
            {
                date: "2023-06-03",
                reason: "Books and e-books",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-05",
                reason: "Tolls and road taxes",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-10",
                reason: "Home cleaning services",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-13",
                reason: "Wedding expenses",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-06-15",
                reason: "Birthday gifts",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-06-20",
                reason: "Music lessons",
                amount: 2000,
                id: new Date().getTime()
            },
        ]
        // data: "https://drive.google.com/file/d/1Q0dYM5iVXicDKKZ598nOy2y5Bc65ROVc/view?usp=sharing"
        // data: "../pdfs/certificate(1).pdf"
    },
    {
        name: "June",
        y: 16000,
        inputType: "row-data",
        data: [
            {
                date: "2023-06-03",
                reason: "Books and e-books",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-05",
                reason: "Tolls and road taxes",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-10",
                reason: "Home cleaning services",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-06-13",
                reason: "Wedding expenses",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-06-15",
                reason: "Birthday gifts",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-06-20",
                reason: "Music lessons",
                amount: 2000,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "July",
        y: 16500,
        inputType: "pdf",
        data: [
            {
                date: "2023-08-03",
                reason: "Legal services",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-05",
                reason: "Business expenses",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-10",
                reason: "Vehicle maintenance plans",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-13",
                reason: "Social club membership fees",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-08-15",
                reason: "Senior care expenses",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-08-20",
                reason: "Hobby or craft club memberships",
                amount: 1500,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "August",
        y: 17500,
        inputType: "row-data",
        data: [
            {
                date: "2023-08-03",
                reason: "Legal services",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-05",
                reason: "Business expenses",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-10",
                reason: "Vehicle maintenance plans",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-08-13",
                reason: "Social club membership fees",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-08-15",
                reason: "Senior care expenses",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-08-20",
                reason: "Hobby or craft club memberships",
                amount: 1500,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "September",
        y: 15000,
        inputType: "row-data",
        data: [
            {
                date: "2023-09-03",
                reason: "Professional cleaning services for businesses",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-05",
                reason: "Home theater system expenses",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-10",
                reason: "Magazine or newspaper subscriptions",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-13",
                reason: "Social club membership fees",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-09-15",
                reason: "Sporting event tickets",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-20",
                reason: "Others",
                amount: 1500,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "October",
        y: 18500,
        inputType: "row-data",
        data: [
            {
                date: "2023-09-03",
                reason: "Home appliance repairs or replacements",
                amount: 2500,
                id: new Date().getTime()
            },
            {
                date: "2023-09-05",
                reason: "Mobile phone insurance",
                amount: 3000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-10",
                reason: "Books and e-books",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-13",
                reason: "Charity donations",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-09-15",
                reason: "Computer/electronics upgrades",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-20",
                reason: "Home improvement projects",
                amount: 2500,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "November",
        y: 16000,
        inputType: "row-data",
        data: [
            {
                date: "2023-09-03",
                reason: "Personal loan payments",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-05",
                reason: "Entertainment (movies, concerts, shows)",
                amount: 2000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-10",
                reason: "Fuel",
                amount: 5000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-13",
                reason: "Internet bill",
                amount: 3500,
                id: new Date().getTime()
            },
            {
                date: "2023-09-15",
                reason: "Electricity Bill",
                amount: 1000,
                id: new Date().getTime()
            },
            {
                date: "2023-09-20",
                reason: "Utilities (electricity, water, gas)",
                amount: 2500,
                id: new Date().getTime()
            },
        ]
    },
    {
        name: "December",
        y: 19000,
        inputType: "pdf",
        data: "https://sanskritdocuments.org/doc_veda/AnobhadrA-gu.pdf"
    }
]