import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Card } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';


const BarChart = ({handleClick, currentItem, setActiveCate, isEdit, data, isCustom}) => {
    console.log('isCustom123', data)
    const navigate = useNavigate();
    const location = useLocation();
    const seriesData = data?.chartData?.header?.columns?.filter((data, ind) => ind !== 0);
    const series = seriesData?.map((row, ind) => {
       return {
        name: row,
       data: data?.rows?.map((row) => parseFloat(row.vals?.[ind+1]))
       }
    })
    let legend = {enabled: true, itemStyle: {fontSize: '10px', fontWeight: '400'}};
    if(data?.legendPosition === 'left'){
        legend = {
            ...legend,
            align: 'left',
            layout: "vertical",
            verticalAlign: 'top',
            x: 0,
            y: 100
        }
    } else if(data?.legendPosition === 'right'){
        legend = {
            ...legend,
            align: 'right',
            layout: "vertical",
            x: 0,
            y: 100,
            verticalAlign: 'top',
        }
    } else if(data?.legendPosition === 'bottom'){
        legend = {
            ...legend,
            align: 'center'
        }
    }
    const options = {
        credits: {
            enabled: false
        },
        chart: {
            type: 'column',
            // height: '50%',
            height: data?.isCustom ? '70%' : '50%',
            className: 'chart-wrapper',
        },
        title: {
            text: data?.name || '-'
        },
        xAxis: {
            labels:{
                style: {
                    fontSize:'9px'
                }
            },
            // categories: [
            //     'Jan',
            //     'Feb',
            //     'Mar',
            //     'Apr',
            //     'May',
            //     'Jun',
            // ],
            categories: data?.rows?.map((row) => row.vals?.[0]),
            crosshair: true,
            ...(data?.showXAxis && {title: {
                text: data?.xAxisTitle
            }})
        },
        yAxis: {
            min: 0,
            title: {
                text: data?.yAxisTitle
            },
            allowDecimals: true
            // tickInterval: 1000,
            // gridLineColor: 'transparent',
            // lineColor: '#002242',
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            valueDecimals: 2
        },
        // legend: {
        //     enabled: true,
        //     align: data?.legendPosition || 'center',
        // },
        legend: {
            ...legend,
            enabled: data?.showLegends ? true : false
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0
            },
            series: {
                pointPadding: 0,
                borderWidth: 0,
                // borderRadius: 10,
                // dataLabels: {
                //     enabled: true,
                // },
                // Click event on each graph point(bar)
                point: {
                    events: {
                        click: function (event) {
                            if(isEdit){
                                let navUrl = `/chart/bar/${currentItem}`;
                                if(data?.isCustom || location.pathname.includes('custom') || window.location.pathname.includes('custom') ){
                                    navUrl = `/chart/bar/${currentItem}?type=custom`;
                                }
                                navigate(navUrl);
                            } else {
                                handleClick(currentItem);
                                setActiveCate(event.point.index);
                            }
                        }
                    }
                }
            }
        },
        series: series
    };
    return (
        <div onClick={()=>{
            if(isEdit){
                navigate(`/chart/bar/${currentItem}`)
            }
        }}>
            <Card className='card-wrapper'>
                <HighchartsReact highcharts={Highcharts} options={options}/>
            </Card>
            {/* {selectedBar == null && <Divider className='my-20' />} */}
            {/* {selectedBar !== null && (
                <CustomTable selectedBar={selectedBar} />
            )} */}
            {/* {
                isModalOpen && selectedBar !== null && (
                    <CustomModal selectedBar={selectedBar} handleOpen={handleOpen} handleClose={handleClose} open={isModalOpen} />
                )
            } */}
        </div>
    )
}
export default BarChart;