import React, { createContext, useContext, useEffect, useState } from 'react';

const BarContext = createContext();

export const BarContextProvider = ({ children }) => {
    const chartData = [
        {
            "name": "Kaizen Activity",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "12",
                        "10"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "12",
                        "13"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "12",
                        "10"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "24",
                        "42"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "40",
                        "50"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "45",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "TPM activity",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "2",
                        "2"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "3",
                        "3"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "4",
                        "3"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "5",
                        "4"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "6",
                        "5"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "7",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "SMED Activity",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Value"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "SMED Type 1 - 2018",
                        "45",
                        "60"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 1 - 2019",
                        "45",
                        "60"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 1 - 2020",
                        "45",
                        "60"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 1 - 2021",
                        "45",
                        "60"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 1 - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 2 - 2018",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 2 - 2019",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 2 - 2020",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 2 - 2021",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 2 - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 3 - 2018",
                        "60",
                        "90"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 3 - 2019",
                        "60",
                        "90"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 3 - 2020",
                        "60",
                        "90"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 3 - 2021",
                        "60",
                        "90"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 3 - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 4 - 2018",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 4 - 2019",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 4 - 2020",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 4 - 2021",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 4 - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 5 - 2018",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 5 - 2019",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 5 - 2020",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 5 - 2021",
                        "30",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "SMED Type 5 - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                }

            ]
        },
        {
            "name": "Customer Complaints",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "No. of complaints"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "24",
                        "58"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "24",
                        "45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "24",
                        "32"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "24",
                        "25"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "24",
                        "19"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "Rejection Rate",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "1.5",
                        "1.62"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "1.5",
                        "1.46"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "1.4",
                        "1.45"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "1.4",
                        "1.60"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "1.35",
                        "1.57"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "1.4",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "Automation 3 Years Plan",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2022",
                        "2",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "3",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2024",
                        "5",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "Renewable Energy",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target (MWh)",
                        "Actual (MWh)"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "1000",
                        "950"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "1200",
                        "1150"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "1400",
                        "1350"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "1600",
                        "1550"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "1800",
                        "1650"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "2000",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "Reprocess Material Usage",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Target",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "2018",
                        "40",
                        "38"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2019",
                        "45",
                        "43.5"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2020",
                        "46",
                        "43.3"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2021",
                        "45",
                        "54.7"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2022",
                        "48",
                        "48"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "2023",
                        "50",
                        "0"
                    ],
                    inputType: 'row-data'
                }
            ]
        },
        {
            "name": "Multi Skill Training",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Value",
                        "None"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "D to C - 2018",
                        "20",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "D to C - 2019",
                        "40",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "D to C - 2020",
                        "20",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "D to C - 2021",
                        "20",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "D to C - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "C to B - 2018",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "C to B - 2019",
                        "25",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "C to B - 2020",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "C to B - 2021",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "C to B - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "B to A - 2018",
                        "15",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "B to A - 2019",
                        "15",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "B to A - 2020",
                        "15",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "B to A - 2021",
                        "15",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "B to A - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "A to A* - 2018",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "A to A* - 2019",
                        "5",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "A to A* - 2020",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "A to A* - 2021",
                        "12",
                        "0"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "A to A* - 2022",
                        "0",
                        "0"
                    ],
                    inputType: 'row-data'
                }

            ]
        },
        // {
        //     "name": "Test Title",
        //     type: "pie",
        //     xAxisTitle: 'xTitle',
        //     yAxisTitle: 'Qty',
        //     series: [{
        //         data: [
        //           {
        //             name: 'test_data1',
        //             y: 100,
        //             inputType: 'pdf',
        //             url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
        //           },
        //           {
        //             name: 'test_data2',
        //             y: 50,
        //             inputType: 'ppt',
        //             url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
        //           },
        //           {
        //             name: 'test_data3',
        //             y: 20,
        //             inputType: ''
        //           }
        //         ]
        //       }]
        // }
    ];
    const customChartData = [
        {
            "name": "Downtime",
            type: "pie",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            isCustom: true,
            showTitle: true,
            series: [{
                data: [
                  {
                    name: 'Nozzle Change',
                    y: 20,
                  },
                  {
                    name: 'Heater',
                    y: 16,
                  },
                  {
                    name: 'Nozzle Filter Clean',
                    y: 10.35,
                    inputType: ''
                  },
                  {
                    name: 'Linear Scale',
                    y: 3.8,
                  },
                  {
                    name: 'Display',
                    y: 2,
                  },
                  {
                    name: 'Ejector Bolt',
                    y: 1.8,
                    inputType: ''
                  },
                  {
                    name: 'Oil leak',
                    y: 0.95,
                  },
                  {
                    name: 'Drive',
                    y: 0.3,
                  },
                  {
                    name: 'SSR',
                    y: 0,
                    inputType: ''
                  }
                ]
              }]
        },
        {
            "name": "Rejection",
            type: "pie",
            isCustom: true,
            showTitle: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Short fill',
                    y: 22323,
                  },
                  {
                    name: 'Silver streaks',
                    y: 8668,
                  },
                  {
                    name: 'Colour change',
                    y: 6775,
                  },
                  {
                    name: 'Flash',
                    y: 2969,
                  },
                  {
                    name: 'Oil/Grease mark',
                    y: 78,
                  },
                  {
                    name: 'Shrinkage',
                    y: 40,
                  },
                  {
                    name: 'Breakage',
                    y: 52,
                  },
                  {
                    name: 'Bend',
                    y: 160,
                  },
                  {
                    name: 'Others',
                    y: 5892,
                  }
                ]
              }]
        },
        {
            "name": "CUSTOMER COMPLAINT WEEK # 30",
            type: "pie",
            showTitle: true,
            xAxisTitle: 'xTitle',
            isCustom: true,
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Zero Complaints',
                    y: 0,
                  },
                ]
              }]
        },
        {
            "name": "Production Efficiency",
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Percentage',
            isCustom: true,
            "chartData": {
                "header": {
                    "columns": [
                        "Days",
                        "Value"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "Monday",
                        "80"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "Tuesday",
                        "82"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "Wednesday",
                        "80"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "Thursday",
                        "77"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "Friday",
                        "79"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "Saturday",
                        "79"
                    ],
                    inputType: 'row-data'
                },
            ]
        },
        {
            "name": "Downtime",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Heater',
                    y: 47.55,
                  },
                  {
                    name: 'Display',
                    y: 22.3,
                  },
                  {
                    name: 'Linear Scale',
                    y: 9.1,
                    inputType: ''
                  },
                  {
                    name: 'Nozzle Filter Clean',
                    y: 22,
                  },
                  {
                    name: 'Oil leak',
                    y: 1.15,
                  },
                  {
                    name: 'Ejector Bolt',
                    y: 5,
                    inputType: ''
                  },
                  {
                    name: 'Drive',
                    y: 0.6,
                  },
                  {
                    name: 'Nozzle Change',
                    y: 1,
                  },
                  {
                    name: 'SSR',
                    y: 0.4,
                    inputType: ''
                  }
                ]
              }]
        },
        {
            "name": "Rejection",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Short Moulding',
                    y: 52603,
                  },
                  {
                    name: 'Silver Streaks',
                    y: 43702,
                  },
                  {
                    name: 'Startup',
                    y: 35300,
                    inputType: ''
                  },
                  {
                    name: 'Flash',
                    y: 20185,
                  },
                  {
                    name: 'Hook Insert Damage',
                    y: 15827,
                  },
                  {
                    name: 'Color Variation',
                    y: 12278,
                    inputType: ''
                  },
                  {
                    name: 'Oil Grease Mark',
                    y: 9304,
                  },
                  {
                    name: 'Bend',
                    y: 7145,
                    inputType: ''
                  }
                ]
              }]
        },
        {
            "name": "CUSTOMER COMPLAINT WEEK # 30",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Hanger breakage',
                    y: 1,
                  },
                ]
              }]
        },
        {
            "name": "Production Efficiency",
            xAxisTitle: 'xTitle',
            isCustom: true,
            yAxisTitle: 'Percentage',
            "chartData": {
                "header": {
                    "columns": [
                        "Week",
                        "Actual"
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "WEEK#23",
                        "950"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "WEEK#24",
                        "1150"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "WEEK#25",
                        "1350"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "WEEK#26",
                        "1550"
                    ],
                    inputType: 'row-data'
                },
            ]
        },

        {
            "name": "Downtime",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'Heater',
                    y: 34,
                  },
                  {
                    name: 'Display',
                    y: 17,
                  },
                  {
                    name: 'Linear Scale',
                    y: 16,
                    inputType: ''
                  },
                  {
                    name: 'Nozzle Filter Clean',
                    y: 21,
                  },
                  {
                    name: 'Oil leak',
                    y: 4,
                  },
                  {
                    name: 'Ejector Bolt',
                    y: 2,
                    inputType: ''
                  },
                  {
                    name: 'Drive',
                    y: 1.5,
                  },
                  {
                    name: 'Nozzle Change',
                    y: 2.2,
                  },
                  {
                    name: 'SSR',
                    y: 1.5,
                    inputType: ''
                  }
                ]
              }]
        },
        {
            "name": "Rejection",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'YR 2018',
                    y: 1.62,
                  },
                  {
                    name: 'YR 2019',
                    y: 1.71,
                  },
                  {
                    name: 'YR 2020',
                    y: 1.74,
                  },
                  {
                    name: 'YR 2021',
                    y: 1.89,
                  },
                  {
                    name: 'YR 2022',
                    y: 1.53,
                  }
                ]
              }]
        },
        {
            "name": "CUSTOMER COMPLAINT WEEK # 30",
            type: "pie",
            showTitle: true,
            isCustom: true,
            xAxisTitle: 'xTitle',
            yAxisTitle: 'Qty',
            series: [{
                data: [
                  {
                    name: 'YR 2018',
                    y: 58,
                  },
                  {
                    name: 'YR 2019',
                    y: 45,
                  },
                  {
                    name: 'YR 2020',
                    y: 32,
                  },
                  {
                    name: 'YR 2021',
                    y: 25,
                  },
                  {
                    name: 'YR 2022',
                    y: 19,
                  }
                ]
              }]
        },
        {
            "name": "Production Efficiency",
            xAxisTitle: 'xTitle',
            isCustom: true,
            yAxisTitle: 'Percentage',
            "chartData": {
                "header": {
                    "columns": [
                        "Year",
                        "Value",
                    ]
                }
            },
            "rows": [
                {
                    "vals": [
                        "YR 2018",
                        "84"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "YR 2019",
                        "81"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "YR 2020",
                        "73"
                    ],
                    inputType: 'row-data'
                },
                {
                    "vals": [
                        "YR 2021",
                        "78"
                    ],
                    inputType: 'row-data'
                },

                {
                    "vals": [
                        "YR 2022",
                        "83"
                    ],
                    inputType: 'row-data'
                },
            ]
        },
    ];
    const pieChartInitialData = [
      {
        name: "Production Efficiency Weekly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "xTitle",
        yAxisTitle: "Qty",
        series: [
          {
            data: [
              {
                name: "IMM M/C",
                y: 237.6,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Mould",
                y: 181.5,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "NPD",
                y: 181.5,
              },
              {
                name: "Auto",
                y: 154,
              },
              {
                name: "Preventive",
                y: 132,
              },
              {
                name: "Material Issue",
                y: 127.6,
              },
              {
                name: "Manpower",
                y: 104.5,
              },
              {
                name: "Mould change",
                y: 104.5,
              },
              {
                name: "Colour change",
                y: 101.2,
              },
              {
                name: "Trial",
                y: 96.8,
              },
            ],
          },
        ],
      },
      {
        name: "Rejection Weekly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Colour Change",
                y: 14463,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Short Moulding",
                y: 10458,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Startup",
                y: 9820,
                inputType: "",
              },
              {
                name: "Machine Issues",
                y: 8125,
                inputType: "",
              },
              {
                name: "Hook Insert Damage",
                y: 5832,
                inputType: "",
              },
              {
                name: "Silver Streaks",
                y: 3877,
              },
              {
                name: "Flash",
                y: 2623,
              },
              {
                name: "Color Variation",
                y: 2532,
              },
              {
                name: "Material Problem",
                y: 914,
              },
              {
                name: "Short Quantity",
                y: 704,
              },
              {
                name: "Pin Mark",
                y: 270,
              },
              {
                name: "Oil Grease Mark",
                y: 246,
              },
              {
                name: "Bend",
                y: 151,
              },
              {
                name: "Breakag",
                y: 114,
              },
              {
                name: "Shrinkage",
                y: 24,
              },
              {
                name: "Black Spot",
                y: 0,
              },
              {
                name: "Excess Material",
                y: 0,
              },
              {
                name: "New Manpower",
                y: 0,
              },
              {
                name: "Water Mark",
                y: 0,
              },
              {
                name: "Weight Variaton",
                y: 0,
              },
            ],
          },
        ],
      },
      {
        name: "Downtime Weekly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Drive",
                y: 15.15,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Thermocouple",
                y: 12.0,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Clampig System",
                y: 9.05,
              },
              {
                name: "Lubrication Related",
                y: 7.1,
              },
              {
                name: "Pump Related",
                y: 7.0,
              },
              {
                name: "Nozzle Filter Clean",
                y: 5.45,
              },
              {
                name: "Ejector cum Scale",
                y: 4.5,
              },
              {
                name: "Others Robo / Tonnage",
                y: 4.5,
              },
              {
                name: "Display 8 pin PLC Cable",
                y: 3.05,
              },
              {
                name: "Connector (Heating Issue)",
                y: 3.0,
              },
              {
                name: "Injection issues",
                y: 3.0,
              },
              {
                name: "Nozzle / Heater",
                y: 3.0,
              },
              {
                name: "Grin.M/c & Dehumid.",
                y: 3.0,
              },
              {
                name: "Safety Related",
                y: 1.15,
              },
              {
                name: "Linear Scale",
                y: 1.0,
              },
            ],
          },
        ],
      },
      {
        name: "Production Efficiency Monthly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "xTitle",
        yAxisTitle: "Qty",
        series: [
          {
            data: [
              {
                name: "IMM M/C",
                y: 877,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Mould",
                y: 477,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "NPD",
                y: 1240,
              },
              {
                name: "Auto",
                y: 574,
              },
              {
                name: "Preventive",
                y: 550,
              },
              {
                name: "Material Issue",
                y: 335,
              },
              {
                name: "Manpower",
                y: 250,
              },
              {
                name: "Mould change",
                y: 295,
              },
              {
                name: "Colour change",
                y: 350,
              },
              {
                name: "Trial",
                y: 290,
              },
            ],
          },
        ],
      },
      {
        name: "Rejection Monthly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Colour Change",
                y: 827,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Short Moulding",
                y: 74957,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Startup",
                y: 27089,
                inputType: "",
              },
              {
                name: "Machine Issues",
                y: 324,
                inputType: "",
              },
              {
                name: "Hook Insert Damage",
                y: 14996,
                inputType: "",
              },
              {
                name: "Silver Streaks",
                y: 23355,
              },
              {
                name: "Flash",
                y: 7210,
              },
              {
                name: "Color Variation",
                y: 18645,
              },
              {
                name: "Material Problem",
                y: 9071,
              },
              {
                name: "Short Quantity",
                y: 0,
              },
              {
                name: "Pin Mark",
                y: 3119,
              },
              {
                name: "Oil Grease Mark",
                y: 1618,
              },
              {
                name: "Bend",
                y: 1004,
              },
              {
                name: "Breakag",
                y: 2909,
              },
              {
                name: "Shrinkage",
                y: 1547,
              },
              {
                name: "Black Spot",
                y: 0,
              },
              {
                name: "Excess Material",
                y: 0,
              },
              {
                name: "New Manpower",
                y: 0,
              },
              {
                name: "Water Mark",
                y: 0,
              },
              {
                name: "Weight Variaton",
                y: 0,
              },
            ],
          },
        ],
      },
      {
        name: "Downtime Monthly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Nozzle Filter Clean",
                y: 131.0,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Display 8 pin PLC Cable",
                y: 94.2,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Pump issues",
                y: 81.0,
              },
              {
                name: "Band Heater",
                y: 72.0,
              },
              {
                name: "Mould  O / C",
                y: 50.5,
              },
              {
                name: "Ejector Scale",
                y: 47.18,
              },
              {
                name: "Thermocouple",
                y: 46.96,
              },
              {
                name: "Nozzle / Heater ",
                y: 43.87,
              },
              {
                name: "Ring Plunger",
                y: 41.18,
              },
              {
                name: "Lubrication Related",
                y: 40.81,
              },
              {
                name: "Linear Scale",
                y: 38.99,
              },
              {
                name: "Clamping system",
                y: 37.18,
              },
              {
                name: "Robo Related",
                y: 29.0,
              },
              {
                name: "Drive related",
                y: 27.2,
              },
              {
                name: "Injection issues",
                y: 24.7,
              },
              {
                name: "Connector (Heating Issue)",
                y: 21.2,
              },
              {
                name: "safety Related ",
                y: 20.0,
              },
              {
                name: "Grin.M/c & Dehumid.",
                y: 14.0,
              },
              {
                name: "Power Related",
                y: 10.0,
              },
              {
                name: "Others",
                y: 0.0,
              },
            ],
          },
        ],
      },
      {
        name: "Production Efficiency Yearly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "xTitle",
        yAxisTitle: "Qty",
        series: [
          {
            data: [
              {
                name: "IMM M/C",
                y: 10380,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Mould",
                y: 6840,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "NPD",
                y: 3288,
              },
              {
                name: "Auto",
                y: 7836,
              },
              {
                name: "Preventive",
                y: 3720,
              },
              {
                name: "Material Issue",
                y: 5448,
              },
              {
                name: "Manpower",
                y: 5880,
              },
              {
                name: "Mould change",
                y: 4920,
              },
              {
                name: "Colour change",
                y: 2760,
              },
              {
                name: "Trial",
                y: 2100,
              },
            ],
          },
        ],
      },
      {
        name: "Rejection Yearly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Colour Change",
                y: 3271,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Short Moulding",
                y: 279813,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Startup",
                y: 158766,
                inputType: "",
              },
              {
                name: "Machine Issues",
                y: 52927,
                inputType: "",
              },
              {
                name: "Hook Insert Damage",
                y: 82897,
                inputType: "",
              },
              {
                name: "Silver Streaks",
                y: 119168,
              },
              {
                name: "Flash",
                y: 65537,
              },
              {
                name: "Color Variation",
                y: 112021,
              },
              {
                name: "Material Problem",
                y: 52505,
              },
              {
                name: "Short Quantity",
                y: 35300,
              },
              {
                name: "Pin Mark",
                y: 25072,
              },
              {
                name: "Oil Grease Mark",
                y: 1766,
              },
              {
                name: "Bend",
                y: 10308,
              },
              {
                name: "Breakag",
                y: 3487,
              },
              {
                name: "Shrinkage",
                y: 1547,
              },
              {
                name: "Black Spot",
                y: 0,
              },
              {
                name: "Excess Material",
                y: 2638,
              },
              {
                name: "New Manpower",
                y: 43702,
              },
              {
                name: "Water Mark",
                y: 17,
              },
              {
                name: "Weight Variaton",
                y: 0,
              },
            ],
          },
        ],
      },
      {
        name: "Downtime Yearly",
        type: "pie",
        showTitle: true,
        xAxisTitle: "Defect",
        yAxisTitle: "Nos",
        series: [
          {
            data: [
              {
                name: "Nozzle Filter Clean",
                y: 131.0,
                // inputType: 'pdf',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/certificate%20%281%29.pdf`
              },
              {
                name: "Display 8 pin PLC Cable",
                y: 94.2,
                // inputType: 'ppt',
                // url: `https://inovec.s3.ap-south-1.amazonaws.com/dummy.e692048ea917f70825d7.pptx`
              },
              {
                name: "Pump issues",
                y: 81.0,
              },
              {
                name: "Band Heater",
                y: 72.0,
              },
              {
                name: "Mould  O / C",
                y: 50.5,
              },
              {
                name: "Ejector Scale",
                y: 47.18,
              },
              {
                name: "Thermocouple",
                y: 46.96,
              },
              {
                name: "Nozzle / Heater ",
                y: 43.87,
              },
              {
                name: "Ring Plunger",
                y: 41.18,
              },
              {
                name: "Lubrication Related",
                y: 40.81,
              },
              {
                name: "Linear Scale",
                y: 38.99,
              },
              {
                name: "Clamping system",
                y: 37.18,
              },
              {
                name: "Robo Related",
                y: 29.0,
              },
              {
                name: "Drive related",
                y: 27.2,
              },
              {
                name: "Injection issues",
                y: 24.7,
              },
              {
                name: "Connector (Heating Issue)",
                y: 21.2,
              },
              {
                name: "safety Related ",
                y: 20.0,
              },
              {
                name: "Grin.M/c & Dehumid.",
                y: 14.0,
              },
              {
                name: "Power Related",
                y: 10.0,
              },
              {
                name: "Others",
                y: 0.0,
              },
            ],
          },
        ],
      },
    ];
    const localStorageData = localStorage.getItem('chartInfo');
    const localStoragePieData = localStorage.getItem('pieChartData');
    const localStorageCustomData = localStorage.getItem('customChartData');
    const localStorageMode = localStorage.getItem('isEditModeOn');
    const [barChartData, setBarChartData] = useState(localStorageData ? JSON.parse(localStorageData) : chartData);
    const [pieChartData, setPieChartData] = useState(localStoragePieData ? JSON.parse(localStoragePieData) : pieChartInitialData);
    const [customChartsData, setCustomChartsData] = useState(localStorageCustomData ? JSON.parse(localStorageCustomData) : customChartData);
    const [isEditModeOn, setIsEditModeOn] = useState(localStorageMode ? JSON.parse(localStorageMode) : false);
    useEffect(() => {
        localStorage.setItem('isEditModeOn', isEditModeOn);
    }, [isEditModeOn])

    useEffect(() => {

        localStorage.setItem('chartInfo', JSON.stringify(barChartData));
    }, [barChartData])

    useEffect(() => {
        localStorage.setItem('pieChartData', JSON.stringify(pieChartData));
    }, [pieChartData])

    useEffect(() => {
        localStorage.setItem('customChartData', JSON.stringify(customChartsData));
    }, [customChartsData])

    return (
        <BarContext.Provider value={{ barChartData, setBarChartData, isEditModeOn, setIsEditModeOn, pieChartData, setPieChartData, customChartsData, setCustomChartsData }}>
            {children}
        </BarContext.Provider>
    );
}

export const useGlobalContext = () => {
    return useContext(BarContext);
}
