import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Vector from '../assets/svg/Vector';
import WorkFlow from '../assets/svg/WorkFlow';
import Require from '../components/Required';
import { useNavigate } from 'react-router-dom';
import CustomLoginButton from '../components/CustomLoginButton';
import {useGlobalContext} from '../utils/Context';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('UserId is Required'),
  password: Yup.string().required('Password is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '4px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  // background: 'linear-gradient(180deg, #265AA8 0%, #AACCFF 104.19%)',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  /* height: '100vh', */
  // overflow: 'auto',
  '.left-contained': {
    background: '#0160B9',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  '.right-contained': {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.vector-workflow-box': {
    marginTop: '114.92px',
    paddingRight: '12%',
    // paddingLeft: '21%',
  },
  '.workFlow-title-box': {
    position: 'absolute',
    bottom: '86px',
  },
  '.workFlow-title': {
    color: '#ffffff',
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '72px',
  },
  // '.login-box': {
  //   //height: '450px',
  //   width: '503px',
  //   justifyContent: 'center',
  // },
  '.head-title': {
    fontSize: '24px',
    fontWeight: '600',
  },
  '.head-sub-title': {
    fontSize: '16px',
    fontWeight: '400',
    color: '#8A8A8A',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
    marginTop: '5px'
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 6px 0px',
    color: '#020202',
    //marginTop: '15px',
  },
  '.input-field': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px',
  },
  '.login-btn-box': {
    display: 'flex',
    // justifyContent: 'center',
    marginTop: '24px',
  },
  '.left-box': {
    width: '10px',
    height: '84px',
    background: '#CC0000',
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC',
  },
  '.close-icon': {
    border: '2px solid red',
    cursor: 'pointer',
  },
  '.blue-bg': {
    backgroundColor: '#0160B9 !important',
    color: 'white !important',
  },
  '.disable-btn': {
    background: '#C9C9C9 !important',
    color: '#FFFFFF !important',
  }
}));

const UserLogin = () => {
  const navigate = useNavigate();
  const [errorBox, setErrorBox] = React.useState(false);
  const { setIsEditModeOn } =useGlobalContext();
  const handleOpen = () => setErrorBox(true);
  const icon = <AiOutlineInfoCircle color='red' />;

  const user = localStorage.getItem('user');
  React.useEffect(()=> {
    if(user){
      navigate('/select-dashboard');
    }
  }, [user])

  return (
    <React.Fragment>
      <HeadBox>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const {email, password} = values;
            if(email === 'dashboard_manager' && password === 'Dash@Jun2023'){
                localStorage.setItem('user', JSON.stringify(values));
                setIsEditModeOn(true);
                navigate('/select-dashboard');
            } else if((email === 'dashboard_user1' && password === 'User1@Jun2023') || (email === 'dashboard_user2' && password === 'User2@Jun2023')) {
              localStorage.setItem('user', JSON.stringify(values));
              setIsEditModeOn(false);
              navigate('/select-dashboard');
            } else {
                localStorage.removeItem('user');
                handleOpen();
            }
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, isValid, dirty }) => (
            <Grid container>
              <Grid item xs={6} className='left-contained'>
                <Box className='vector-workflow-box'>
                  <Box display={'flex'}>
                    <Box>
                      <Vector />
                    </Box>
                    <Box ml={'13px'} pt={'17px'}>
                      {/* <WorkFlow /> */}
                    </Box>
                  </Box>
                  <Box className='workFlow-title-box'>
                    <Typography className='workFlow-title'>Powerful</Typography>
                    <Typography className='workFlow-title'>Analytics</Typography>
                    <Typography className='workFlow-title'>Made Simple.</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} className='right-contained'>
                <Form onSubmit={handleSubmit}>
                  <Box className='login-box'>
                    <Box>
                      <Typography className='head-title'>Welcome</Typography>
                      {/* <Typography className='head-sub-title'>
                        Login to account using your User Id
                      </Typography>
                      <Typography className='head-sub-title'>
                        and Password provided by your organization.
                      </Typography> */}
                    </Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box>
                          <Typography className='field-title' mt={'33px'}>
                            User ID
                            <Require />
                          </Typography>

                          <Input
                            placeholder='Enter your User Id'
                            fullWidth
                            className='input-field'
                            onChange={handleChange}
                            name='email'
                            InputProps={{
                              endAdornment: touched.email && errors.email && icon,
                            }}
                          />
                          {touched.email && errors.email && (
                            <Typography className='validation-text'>{errors.email}</Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography className='field-title' mt='25px'>
                            Password
                            <Require />
                          </Typography>
                          <Input
                            placeholder='Enter Password'
                            fullWidth
                            type='password'
                            className='input-field'
                            onChange={handleChange}
                            name='password'
                            InputProps={{
                              endAdornment: touched.password && errors.password && icon,
                            }}
                          />
                          {touched.password && errors.password && (
                            <Typography className='validation-text'>{errors.password}</Typography>
                          )}
                          {
                            errorBox && <Typography className='validation-text'>{'Invalid User Credentials!!!'}</Typography>
                          }
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className='login-btn-box'>
                    <CustomLoginButton
                            type='submit'
                            className={!(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                          >
                            Login
                          </CustomLoginButton>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            </Grid>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  );
};
export default UserLogin;
